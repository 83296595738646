<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Child safety (Expiring soon)</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Child safety
                </div>
              </div>
              <div class="breadcrumb-right">
                <v-btn
                       @click="exportChildSafety()"
                       class="mt-4 btn btn-primary  mr-1"
                       dark>
                  <i class="fa fa-file-csv"></i>
                  Export
                </v-btn>

              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Search by name, phone or email"
                      v-model="search.user_info"
                      outlined
                      dense
                      v-on:keyup.enter="searchWWCCExpiryDate()"
                      @input="search.user_info = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12" md="3">
                  <v-select
                      :items="userTypes"
                      v-model="search.user_type"
                      label="Team role"
                      item-text="name"
                      item-value="value"
                      v-on:keyup.enter="searchWWCCExpiryDate()"
                      @input="search.user_type = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="currentUser.access_type!='score'">
                  <v-select
                      :items="scores" v-on:keyup.enter="searchWWCCExpiryDate"
                      v-model="search.score_id"
                      label="SCORE"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" :md="currentUser.access_type!='score' ? 3 : 6">
                  <v-btn
                      @click="searchWWCCExpiryDate()"
                      class="btn btn-primary text-white float-right"
                      :loading="isLoading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="text-left">

                  <th class="px-3">Name</th>
                  <th class="px-3">Surname</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Email</th>
<!--                  <th class="px-3">Unique IDs </th>-->
                  <th class="px-3">Phone</th>
                  <th class="px-3">WWCC</th>
                  <th class="px-3">Expiry date</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Days to expire</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">SCORE</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-if="supervisors.length > 0" v-for="item in supervisors">

                    <td class="px-3">
                       <a href="#" @click="viewAccountHolderInfo(item)">{{item.first_name}}</a>
                    </td>
                    <td class="px-3">
                      {{item.last_name }}
                    </td>
                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="mb-1" v-copy="item.email">
                        {{item.email ? item.email : 'NA' }}
                      </span>
                  </td>
<!--                    <td class="px-3">-->
<!--                      <a class="font-weight-bolder text-info text-dark-75" @click="goToExaminerOrSupervisor(item)">-->
<!--                        {{item.unique_identifier ? item.unique_identifier : 'NA' }}-->
<!--                      </a>-->
<!--                    </td>-->

                    <td class="text-left">
                      <span class="mb-1" v-copy="item.preferred_phone"> {{item.preferred_phone}}</span>
                    </td>
                    <td class="text-left">
                      <span class="">{{item.wwcc_number}}</span>
                    </td>
                    <td class="text-left">
                      <span class="">{{item.formatted_wwcc_expiry_date}}</span>
                    </td>
                    <td class="text-left">
                      <span class="badge badge-danger mt-2 ml-4" v-if="item.wwcc_expiry_status=='expired'">Expired</span>
                      <span class="badge badge-info mt-2 ml-4" v-else>{{item.wwcc_expiry_date_in_month}} days</span>
                    </td>
                    <td style="max-width: 270px !important; white-space: pre-wrap;" class="text-left">
                      {{item.score_purchase_prefix}}
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="sendReminderMail(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-envelope"></i>
                                </span>
                                <span class="navi-text">Send reminder email</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="supervisors.length == 0">
                    <td colspan="6" class="text-center">
                      <strong>No data found</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination :disabled="isLoading"
                            v-if="supervisors.length > 0"
                            class="pull-right mt-7"
                            @input="getAllWWCCExpiryDate"
                            v-model="page"
                            :total-rows="total"
                            :per-page="perPage"
                            first-number
                            last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import UserSettingService from "@/services/user/UserSettingService";
import ScoreService from "@/services/score/score/ScoreService";

const score = new ScoreService();
const userSetting = new UserSettingService();
export default {
  name: "ShowWWCCExpired",
  data(){
    return{
      isLoading: true,
      supervisors:[],
      total: null,
      perPage: null,
      page: null,
      search:{
        user_info: '',
        user_type: '',
        score_id:'',
      },
      userTypes: [
        { name: "Examiner", value: 'is_examiner'},
        { name: "Supervisor", value: 'is_supervisor'},
      ],
      currentUser:{},
      scores:[],
    }
  },
  mounted() {
    this.getCurrentUser();
    if(this.currentUser.access_type == 'score'){
      this.search.score_id = this.currentUser.score_id;
    }
    this.getAllWWCCExpiryDate();
    this.getAllScore();
  },
  methods: {
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    },
    viewAccountHolderInfo(user) {
      // if(user.user_is_candidate){
      //   this.$router.push({
      //     name: 'candidate-summary',
      //     params: {examKey: user.user_id}
      //   })  
      // }
      if(user.user_is_examiner){
        this.$router.push({
          name: 'examiner-edit',
          params: {examinerId: user.user_id}
        })
      }
      // if(user.user_is_account_holder){
      //   this.$router.push({
      //     name: 'accountholder-edit',
      //     params: {accountholderId: user.user_id}
      //   })
      // }
      if(user.user_is_supervisor){
        this.$router.push({
          name: 'supervisor-edit',
          params: {supervisorId: user.user_id}
        })
      }
      // if(user.user_is_online_marker){
      //   this.$router.push({
      //     name: 'online-marker-edit',
      //     params: {onlineMarkerId: user.user_id}
      //   })
      // }
    },
    getAllScore(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    searchWWCCExpiryDate() {
      this.getAllWWCCExpiryDate();
    },
    getAllWWCCExpiryDate() {
      this.isLoading = true;
      
      userSetting.getAllWWCCExpiryDate(this.search, this.page).then(response => {
          this.supervisors = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
      })
      .catch(() => {})
      .finally(() =>
          this.isLoading = false
      )
    },
    exportChildSafety() {
      userSetting.exportChildSafety(this.search);
    },
    goToExaminerOrSupervisor(item) {
      if(item.user_is_examiner == 1) {
        this.$router.push({name: "examiner-edit", params: {examinerId: item.user_id}});
      }
      if(item.user_is_supervisor == 1) {
        this.$router.push({name: "supervisor-edit", params: {supervisorId: item.user_id}});
      }
    },
    sendReminderMail(id) {
      this.$confirm({
        message: `Are you sure you want to send reminder mail ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            userSetting
            .reminderExpiryDate(id)
            .then(response => {
              if(response.data.status == "OK") {
                this.$snotify.success("Reminder Mail Has been Sent");
              }
            })
            .catch(() => {

            });
          }
        }
      });
    }
  }
}
</script>

<style scoped>

</style>